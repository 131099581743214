import Vue from 'vue'
import Vuetify, { VTextField, VCheckbox } from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VTextField,
    VCheckbox
  }
})

export default new Vuetify({
})
